require('@rails/ujs').start();
require('turbolinks').start();
// require('@rails/activestorage').start();
// require('channels');

const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

import '../css/application.css';
